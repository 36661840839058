<script>
import axios from "axios";
export default {
  data() {
    return {
      listPelaksanaan: [],
      formData: {
        keterangan: "",
        tanggal_mulai: "",
        tanggal_selesai: "",
        jadwal_pelaksanaan_idjadwal_pelaksanaan: "",
      },
    };
  },
  mounted() {
    this.getJadwalPelaksanaan();
  },
  methods: {
    async getJadwalPelaksanaan() {
      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/jadwal-pelaksanaan/all",
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log("err get jadwal pelaksanaan", err);
          return false;
        });
      if (data) {
        this.listPelaksanaan = data;
        this.formData.jadwal_pelaksanaan_idjadwal_pelaksanaan =
          this.listPelaksanaan[0];
      }
    },
    submitForm() {
      const authToken = localStorage.access_token;
      let payload = { ...this.formData };
      if (payload.jadwal_pelaksanaan_idjadwal_pelaksanaan) {
        payload.jadwal_pelaksanaan_idjadwal_pelaksanaan =
          payload.jadwal_pelaksanaan_idjadwal_pelaksanaan.idjadwal_pelaksanaan;
      }
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pelaksanaan",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data);
          this.resetForm();
          window.location.reload();
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error.response.data);
        });
    },
    resetForm() {
      this.formData.keterangan = "";
      this.formData.tanggal_mulai = "";
      this.formData.tanggal_selesai = "";
      this.formData.jadwal_pelaksanaan_idjadwal_pelaksanaan = "";
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="name" class="form-label">Pelaksanaan</label>
        <v-select
          id="parent_menu"
          class="style-chooser"
          :options="listPelaksanaan"
          :clearable="false"
          label="pelaksanaan"
          v-model="formData.jadwal_pelaksanaan_idjadwal_pelaksanaan"
          placeholder="Pelaksanaan"
        />
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Keterangan</label>
        <textarea
          v-model="formData.keterangan"
          class="form-control"
          id="name"
          required
        ></textarea>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <label for="email" class="form-label">Tanggal Mulai</label>
          <input
            v-model="formData.tanggal_mulai"
            type="date"
            class="form-control"
            id="tanggal_mulai"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>
        <div class="col-6">
          <label for="email" class="form-label">Tanggal Selesai</label>
          <input
            v-model="formData.tanggal_selesai"
            type="date"
            class="form-control"
            id="tanggal_selesai"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
