<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import ModalAddJadwal from "./modal-add-jadwal.vue";
import ModalEditJadwal from "./modal-edit-jadwal.vue";
import ModalMonitorJadwal from "./modal-monitor-jadwal.vue";
import permissionService from "../../../../helpers/permissionService";

export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
    ModalAddJadwal,
    ModalEditJadwal,
    ModalMonitorJadwal,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "Kode Tes",
        name: "kode_tes",
      },
      {
        width: "auto",
        label: "Pelaksanaan",
        name: "-",
      },
      {
        width: "auto",
        label: "Keterangan",
        name: "keterangan",
      },
      {
        width: "150px",
        label: "Tanggal Mulai",
        name: "tanggal_mulai",
      },
      {
        width: "150px",
        label: "Tanggal Selesai",
        name: "tanggal_selesai",
      },
      {
        width: "100px",
        label: "Status",
        name: "-",
      }, // jika tidak ada sorting kasih name dengan karakter -
      {
        width: "370px",
        label: "Aksi",
        name: "-",
      }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    // Get hak akses
    const menu = "Jadwal Pelaksanaan";

    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      isRead: localStorage.getItem("isRead"),
      isWriteTambah: 0,
      isReadTambah: 0,
      isWriteEdit: 0,
      isReadEdit: 0,
      isWriteSetup: 0,
      isReadSetup: 0,
      isWriteDelete: 0,
      isReadDelete: 0,
      isWriteMonitor: 0,
      isReadMonitor: 0,
      showModalAdd: false,
      showModalEdit: false,
      showModalMonitor: false,
      selected_id: "ss",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "kode_tes", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableFilter: {
        page: 1,
        per_page: 10,
        search: "",
        column: "kode_tes",
        dir: "desc",
        filter_status: "all", // untuk filtering data
        kode_tes: "",
        keterangan: "",
        jadwal_pelaksanaan_idjadwal_pelaksanaan: "",
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      listFilterJadwalPelaksanaan: [],
    };
  },
  async mounted() {
    this.cekPermission("tambah", "Tambah Jadwal");
    this.cekPermission("edit", "Edit Jadwal");
    this.cekPermission("delete", "Delete Jadwal");
    this.cekPermission("setup", "Setup Jadwal");
    this.cekPermission("monitor", "Monitor Jadwal");
    this.getFilterPelaksanaan();

    this.filterData = _.debounce(this.getDataTable.bind(this), 500);

    await this.getDataTable();
  },
  methods: {
    async cekPermission(type, permission) {
      const data = await permissionService.getHakAkses(permission);
      if (type == "tambah") {
        this.isWriteTambah = data.is_write;
        this.isReadTambah = data.is_read;
      } else if (type == "edit") {
        this.isWriteEdit = data.is_write;
        this.isReadEdit = data.is_read;
      } else if (type == "setup") {
        this.isWriteSetup = data.is_write;
        this.isReadSetup = data.is_read;
      } else if (type == "delete") {
        this.isWriteDelete = data.is_write;
        this.isReadDelete = data.is_read;
      } else if (type == "monitor") {
        this.isWriteMonitor = data.is_write;
        this.isReadMonitor = data.is_read;
      }
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    async getFilterPelaksanaan() {
      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/jadwal-pelaksanaan/all",
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log("err get jadwal pelaksanaan", err);
          return false;
        });
      if (data) {
        this.listFilterJadwalPelaksanaan = data;
      }
    },
    async getDataTable() {
      this.loadingTable = true;

      let payload = { ...this.tableFilter };
      if (payload.jadwal_pelaksanaan_idjadwal_pelaksanaan) {
        payload.jadwal_pelaksanaan_idjadwal_pelaksanaan =
          payload.jadwal_pelaksanaan_idjadwal_pelaksanaan?.idjadwal_pelaksanaan;
      }

      axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pelaksanaan",
        params: payload,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            this.table_data = response_data_fix.data;
            this.configPagination(response_data_fix);
            this.currentTablePage = response_data_fix.current_page;
            this.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    async monitor(row) {
      console.log(row);
      this.showModalMonitor = true;
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pelaksanaan/" +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    toPage: function (url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      this.getDataTable();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Jadwal Pelaksanaan</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <button
                    v-on:click="showModalAdd = !showModalAdd"
                    class="btn btn-sm btn-success"
                    v-if="isWriteTambah == 1"
                  >
                    <i class="fa fa-plus"></i> Tambah
                  </button>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="filterKodeTes">Kode Tes</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Filter Kode Tes"
                      v-model="tableFilter.kode_tes"
                      @input="filterData"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="filterKeterangan">Pelaksanaan</label>
                    <v-select
                      id="filterKeterangan"
                      class="style-chooser"
                      :options="listFilterJadwalPelaksanaan"
                      :deselectFromDropdown="true"
                      label="pelaksanaan"
                      v-model="
                        tableFilter.jadwal_pelaksanaan_idjadwal_pelaksanaan
                      "
                      placeholder="Filter Pelaksanaan"
                      @input="getDataTable"
                      @clear="getDataTable"
                      @option:selected="getDataTable"
                      @option:deselected="getDataTable"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="filterKeterangan">Keterangan</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Filter Keterangan"
                      v-model="tableFilter.keterangan"
                      @input="filterData"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select
                      class="form-select"
                      id="statusTable"
                      v-model="tableFilter.filter_status"
                      @change="getDataTable()"
                    >
                      <option selected value="all">Semua</option>
                      <option value="0">Batal</option>
                      <option value="1">Aktif</option>
                      <option value="2">Selesai</option>
                      <option value="3">Draft</option>
                    </select>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableFilter.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableFilter.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="99" class="text-center">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="99">
                      Data Tidak Tersedia
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row_data, key_data) in table_data"
                    :key="key_data"
                  >
                    <td class="text-center">
                      {{ pagination.from + key_data }}
                    </td>
                    <td>
                      {{ row_data.kode_tes }}
                    </td>
                    <td>
                      {{ row_data.jadwal_pelaksanaan?.pelaksanaan || "-" }}
                    </td>
                    <td>
                      {{ row_data.keterangan }}
                    </td>

                    <td class="text-center">
                      {{ row_data.tanggal_mulai }}
                    </td>
                    <td class="text-center">
                      {{ row_data.tanggal_selesai }}
                    </td>
                    <td class="text-center">
                      <div v-if="row_data.status_jadwal == 0">
                        <div
                          class="badge badge-pill badge-soft-danger font-size-12"
                        >
                          Batal
                        </div>
                      </div>
                      <div v-else-if="row_data.status_jadwal == 1">
                        <div
                          class="badge badge-pill badge-soft-info font-size-12"
                        >
                          Aktif
                        </div>
                      </div>
                      <div v-else-if="row_data.status_jadwal == 2">
                        <div
                          class="badge badge-pill badge-soft-success font-size-12"
                        >
                          Selesai
                        </div>
                      </div>
                      <div v-else-if="row_data.status_jadwal == 3">
                        <div
                          class="badge badge-pill badge-soft-warning font-size-12"
                        >
                          Draft
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <div
                        v-if="
                          row_data.status_jadwal == 3 ||
                          row_data.status_jadwal == 1
                        "
                      >
                        <div class="btn-group">
                          <router-link
                            type="button"
                            class="btn btn-info btn-sm"
                            :to="{
                              path: `/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/${row_data.idjadwal}`,
                            }"
                            v-if="isWriteSetup == 1 || isReadSetup == 1"
                          >
                            <i class="fa fa-wrench"></i> Setup Jadwal
                          </router-link>
                          <!-- <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="
                            selected_id = row_data.idjadwal;
                            monitor(row);
                          "
                        >
                          <i class="fa fa-desktop"></i> Monitor
                        </button> -->
                          <router-link
                            type="button"
                            class="btn btn-primary btn-sm"
                            :to="{
                              path: `/master-penjadwalan/jadwal-pelaksanaan/monitor-jadwal/${row_data.idjadwal}`,
                            }"
                            v-if="isWriteMonitor == 1"
                          >
                            <i class="fa fa-eye"></i> Monitor Jadwal
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-on:click="
                              showModalEdit = !showModalEdit;
                              selected_id = row_data.idjadwal;
                            "
                            v-if="isWriteEdit == 1"
                          >
                            <i class="fa fa-edit"></i> Edit
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            v-on:click="deleteData(row_data.idjadwal)"
                            v-if="isWriteDelete == 1"
                          >
                            <i class="fa fa-trash"></i> Hapus
                          </button>
                        </div>
                      </div>
                      <div v-else>
                        <div class="btn-group">
                          <!-- <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-on:click="
                              changeStatusData('ENABLE', row_data.user_id)
                            "
                          >
                            <i class="fas fa-undo"></i>
                            Restore
                          </button> -->
                          <router-link
                            type="button"
                            class="btn btn-info btn-sm"
                            :to="{
                              path: `/master-penjadwalan/jadwal-pelaksanaan/setup-jadwal/${row_data.idjadwal}`,
                            }"
                          >
                            <i class="fa fa-search"></i> Lihat Jadwal
                          </router-link>
                          <router-link
                            type="button"
                            class="btn btn-primary btn-sm"
                            :to="{
                              path: `/master-penjadwalan/jadwal-pelaksanaan/monitor-jadwal/${row_data.idjadwal}`,
                            }"
                            v-if="isWriteMonitor == 1"
                          >
                            <i class="fa fa-eye"></i> Monitor Jadwal
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            v-on:click="deleteData(row_data.idjadwal)"
                            v-if="isWriteDelete == 1"
                          >
                            <i class="fa fa-trash"></i> Hapus
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal
    v-model="showModalMonitor"
    hide-footer
    title="Monitor Jadwal Pelaksanaan"
    size="xl"
    scrollable
  >
    <ModalMonitorJadwal :idJadwal="selected_id" v-if="showModalMonitor" />
  </b-modal>
  <b-modal v-model="showModalAdd" hide-footer title="Tambah Jadwal Pelaksanaan">
    <ModalAddJadwal />
  </b-modal>
  <b-modal v-model="showModalEdit" hide-footer title="Edit Jadwal Pelaksanaan">
    <ModalEditJadwal :id="selected_id" v-if="showModalEdit" />
  </b-modal>
</template>
